
.Page_AccessDash_Details .data_select{
    width: 140px;
}
.Page_AccessDash_Details .pass, .Page_AccessDash_Details .dash_user_name {
    width: 120px;
}
.Page_AccessDash_Details .dash_user_email {
    width: 220px;
}
.Page_AccessDash_Details .list_name_page{
    display: flex;
    gap: 8px;
}
.Page_AccessDash_Details .div_name_page_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}

@media only screen and (max-width: 560px) {
    .Page_AccessDash_Details input, .Page_AccessDash_Details .pass, .Page_AccessDash_Details .dash_user_name{
        width: calc(100% - 24px) !important;
    }
    .Page_AccessDash_Details select {
        width: 100% !important;
    }
    .div_input{
        flex-grow: 1;
    }
}