.PopUp .ReturnResponse{
    width: 400px;
}
.PopUp .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .title_return{
    font-size: 16px;
    font-family: 'Inter';
}
.PopUp .close_return{
    background-color: #111827;
    color: #ffffff;
    width: 300px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 20px;
}

@media only screen and (max-width: 560px) {
    .PopUp .ReturnResponse {
        width: 90%;
    }
}
