.PopUp .Confirmation {
    width: 400px;
}
.PopUp .title_confirmation{
    justify-content: center;
}
.PopUp .opt_select {
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    width: 100px;
    text-align: center;
}
.PopUp .content_confirmation{
    flex-direction: row;
}
.PopUp .yes_update {
    background-color: #76c57e;
}
.PopUp .yes_update:hover {
    background-color: #43AE88;
}
.PopUp .not_update {
    background-color: #e95656;
}
.PopUp .not_update:hover {
    background-color: #CC4D7B;
}

@media only screen and (max-width: 560px) {
    .PopUp .Confirmation {
        width: 90%;
    }
}