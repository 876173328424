.Page_Revenue{
    width: 100%;
    height: auto;
}
.Page_Revenue .icons {
    cursor: pointer;
}
.Page_Revenue .icon_user{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
}
.Page_Revenue .icon_site{
    width: 24px;
    height: 24px;
}
@media only screen and (max-width: 560px) {
    .Page_Revenue .td_mobile{
        display: none;
    }
}